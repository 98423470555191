.page {

    background: linear-gradient(to bottom right, #ffffff, #fffcfa, #fdf9f3);
    text-align: center;
    height: 100vh; 
    justify-content: center;
    align-items: center;
  }
  
  .page-content{
    margin-top: 0px;
  }
/* Main container styling */
.menu-item {
    display: flex;
    flex-direction: row;
    padding: 10px;
    margin: 20px auto;
    background-color: #f9f9f9;
    border: none;
    border-radius: 16px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.2s ease;
    padding: 20px;
    margin: 20px;
    border: 1px solid #ffa034;

}

/* Left section for dish name and description */
.menu-description {
    flex: 1;
    padding-right: 15px;
}

/* Dish title styling */
.dish-title {
  text-align: left;
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  color:#ffa034;
  font-size: 22px;
  font-family: 'Kaushan Script'
}

/* Dish description styling */
.dish-options {
  text-align: left;
    font-weight: bold;
    font-size: 16px;
    font-family: 'Kaushan Script', cursive;
    color: black;
    
}

/* Right section for price */
.menu-price {
    display: flex;
    align-items: baseline;
    justify-content: center;
    padding: 10px;
    font-size: 1em;
    color:#ffa034;
    font-size: 18px;
    font-family: 'Kaushan Script'
 
}
.allergens{
color: #3909aace;
}
.allergens{
  color: #3909aace;
}
.categoryTitle{
  background-color: black;
  margin-top: 10px;
  padding: 20px 0px 20px 20px;
  border-bottom: 2px solid #095c91;
  color: white;
      font-size: 35px;
    font-family: 'Kaushan Script'
}
/* Responsive adjustments */
@media (max-width: 600px) {
    .menu-item {
        flex-direction: column;
        align-items: stretch;
    }

    .menu-description {
        border-right: none;
        border-bottom: 3px solid black;
        padding-right: 0;
        margin-bottom: 10px;
    }
}
  
  
  
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    padding: 1rem;
  }
  .title{
    background-color: #d9534f;
    margin-top: 10px;
    padding: 20px 0px 20px 20px;
    border-bottom: 2px solid #095c91;
    color: white;
    font-size: 35px;
    font-family: 'Kaushan Script';
    padding: 20px;
    width: 100%;
  }
  
  .info {
    margin-bottom: 1rem;
    text-align: center;
  }
  
  .map-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    width: 100%;
    height: 0;
    overflow: hidden;
  }
  
  .map {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
  
/* Hide the mobile header by default */
.mobile-header {
    display: none;
}

/* Display the mobile header only on screens smaller than 768px */
@media (max-width: 768px) {
    .mobile-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 15px;
        background-color: #333;
        color: #fff;
    }
    
    .header-logo {
        font-size: 1.2em;
        font-weight: bold;
    }
    
    .header-phone {
        font-size: 1em;
    }
    
    .burger-menu {
        background: none;
        border: none;
        color: #fff;
        font-size: 1.5em;
        cursor: pointer;
    }
    .mobile-header-image{
    background-image: url('../../assets/images/header-image.jpg');
    background-position: center center;
    background-size: cover;
    left: 0;
    width: 100%;
    height: 300px;

    background-repeat: no-repeat;
    z-index: 0;
    }
}

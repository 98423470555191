#navbar {
  position: fixed;
  top: 0;
  z-index: 1000; /* Ensure it stays on top of other elements */
  width: 100%; /* Full width */
  height: 60px;
  min-width: 400px;
}
/* Navbar options color */
.navbar .dropdown-item:active,
.navbar .dropdown-item:focus {
  background-color: #f4a261;
  color: black;
}

.header-nav-phone-top{
display: flex;
color: white;
font-weight: bold;
text-align: center;
display: none;
}

.header-nav-open-hour-top{
  color: white;
  font-weight: bold;
  text-align: center;
  display: none;
  visibility: hidden;
  }

/* home button  */
.horizontal-menu{
  list-style-type: none;
  display: inline-flex;
  padding-top: 15px;

}
.horizontal-menu li a{
  padding: 0px 0px 0px 0px;
}
.horizontal-menu li{
  margin: 10px;
}
.horizontal-menu li a{
  text-decoration: none; 
  color: #f4a261;
  font-variant: small-caps;
}
.horizontal-menu li a:hover{
  text-decoration: none;
  color: white;
  border-bottom: 1px solid #f4a261;
  border-top: 1px solid #f4a261; /* Optional: underline effect */

}

.horizontal-menu li .active {
  color: white; /* Highlight color for the active link */
  font-weight: bold; /* Optional: make the active link bold */
  border-bottom: 2px solid #f4a261; /* Optional: underline effect */
  
}

.icon-menu{
  background-color: #f1f1f1;
  height: 30px;
  align-items: center;
  padding: 0px;
  }
  
.sidenav {
      height: 100%;
      width: 0;
      position: fixed;
      z-index: 1;
      top: 0;
      right: 0;
      background-color: #111;
      overflow-x: hidden;
      transition: 0.5s;
      padding-top: 60px;
    }
    
    .sidenav a {
      padding: 8px 8px 8px 32px;
      text-decoration: none;
      font-size: 25px;
      color: #818181;
      display: block;
      transition: 0.3s;
    }
    
    .sidenav a:hover {
      color: #f1f1f1;
    }
    
    .sidenav .closebtn {
      position: absolute;
      top: 0;
      right: 25px;
      font-size: 36px;
      margin-left: 50px;
    }
  
  /* HamburgerMenu.css */
  .hamburger-menu {
      position: absolute;
    }
    
    .hamburger-icon {
      display: none;
      flex-direction: column;
      justify-content: space-between;
      width: 30px;
      height: 20px;
      cursor: pointer;
    }
    
    .bar {
      width: 100%;
      height: 4px;
      background-color: #333;
      transition: all 0.3s ease;
    }
    
  
  
    /* Hamburger Icon Transformation When Open */
  .hamburger-icon.open .bar:nth-child(1) {
      transform: rotate(45deg) translate(5px, 5px);
    }
    
    .hamburger-icon.open .bar:nth-child(2) {
      opacity: 0;
    }
    
    .hamburger-icon.open .bar:nth-child(3) {
      transform: rotate(-45deg) translate(5px, -5px);
    }
    
    
    @media screen and (max-height: 450px) {
      .sidenav {padding-top: 15px;}
      .sidenav a {font-size: 18px;}
    }
  
    /* Aflösung kleiner als 768px */
  @media(max-width: 992px){
      .menu {
        display: none; /* hidden menu */
        position: absolute;
        top: 40px;
        left: 0;
        background-color: #000000;
        color: white;
        width: 100%;
        text-align: center;
        padding: 10px 0;
      }
      .hamburger-icon {
        display: flex; /* show burger icon */
        width: 30px;
        height: 20px;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;
        z-index: 100;
        background-color: white;
        color: black;
      }
      .icon-menu{
          background-color: #f1f1f1;
          height: 30px;
          align-items: center;
          padding: 4px;
          }
      .menu.open {
        display: flex;
      }
      .header-nav-phone-top{
        display: flex;
        color: white;
        font-weight: bold;
        text-align: center;
        display: block;
        }
        .header-nav-open-hour-top{
          color: white;
          font-weight: bold;
          text-align: center;
          display: block;
          color: #000000;
          display: block;
          }
        
    
    }
    
  

.container-header {
  width: 100%;
  overflow: hidden;
}

.header-banner {
  background-image: url('../../assets/images/header-image.jpg');
  background-size: cover;
  background-position: center;
  backdrop-filter: blur(5px);
  justify-content: center;
  height: 500px;
  position: relative;
  color: white;
  padding: 40px 20px;
}

.header-top{
  align-content: space-evenly;

}
.header-bottom{
  display: flex;
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 20px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.6);
  text-align: center;

  align-items: center;
  justify-content: center;

}
.header-order{
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.4);
  transition: transform 0.3s, box-shadow 0.3s;
  width: 400px;
  justify-content:center;
  align-items: center;
  border: 1px solid white;
  border-radius: 5px;
  padding: 10px;
}

.header-title {
  font-size: 2.5rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 20px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.6);
}

.header-logo-container {
  padding: 15px;
  border-radius: 10px;
  width: 200px;
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.4);
  transition: transform 0.3s, box-shadow 0.3s;
  float: left;
}
.header-text-container {
  padding: 0px 0px 15px 15px;
  border-radius: 10px;
  width: 200px;
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.4);
  transition: transform 0.3s, box-shadow 0.3s;
  float: right;
}

.logo {
  max-height: 90px;
  border-radius: 8px;
}

@media (max-width: 992px) {
  .container-header {
    height: 300px;
    overflow: hidden;
  }
  header-banner {
    background-image: url('../../assets/images/header-image.jpg');

    background-position: center;
    backdrop-filter: blur(5px);
    justify-content: center;
    height: 100px;
    position: relative;
    color: white;
  }
  
  .header-top{
    align-content: space-evenly;
  
  }
  .header-bottom{
    display: flex;
    font-size: 1.2rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 20px;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.6);
    text-align: center;
    align-items: center;
    justify-content: center;
  
  }
  .header-order{
    justify-content:center;
    align-items: center;
    border-radius: 5px;
    padding: 10px;
    font-weight: 400;
    display: none


  }
  
  .header-title {
    font-size: 2.5rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 20px;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.6);
    display: none;
  }
  
  .header-logo-container {
    padding: 15px;
    border-radius: 10px;
    width: 200px;

    float: left;
    display: none;
  }
  .header-text-container {
    padding: 15px;
    border-radius: 10px;

    display: none;

  }
  .logo {
      max-height: 60px;
  }
  .contact-info{
    visibility: hidden;
  }
}

.header-title {
  font-family: 'Kaushan Script', cursive;
  font-size: 2.5em;
  color: white;
  margin: 0;
}

.header-subtitle {
  font-size: 1.5em;
  color: white;
  margin-top: 5px;
}
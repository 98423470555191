body {
  min-width: 400px;
}
.App-link {
  color: #61dafb;
}
.wraper{
  margin-top: 50px;
}
.loading{
  padding-top: 100px;
}
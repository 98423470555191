/* Home.css */
.nopadding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.text-in-image-centered {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50%;
  height: 50%;
  transform: translate(-50%, -50%);
  color: #f2f2f2;
  background-color: rgba(0, 0, 0, 0.4); /* Adjust color and transparency here */
  align-items: center;
  text-align: center;

}


.home-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f9f9f9;
    padding: 20px;
  }
  
  .home-content {
    max-width: 600px;
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
    font-family: 'Arial', sans-serif;
    color: #333;
  }
  
  .home-title {
    font-family: 'Kaushan Script', cursive;
    font-size: 2.5em;
    color: #d9534f;
    margin: 0;
  }
  
  .home-subtitle {
    font-size: 1.5em;
    color: #5a5a5a;
    margin-top: 5px;
  }
  
  .contact-info, .offer, .location {
    margin-top: 20px;
    font-size: 1em;
    line-height: 1.6;
  }
  
  .contact-info p, .offer p, .location p {
    margin: 5px 0;
  }
  
  .offer {
    background-color: #f2f2f2;
    padding: 15px;
    border-radius: 8px;
    font-weight: bold;
  }
  
  .offer strong {
    color: #d9534f;
  }
  
  .location {
    font-style: italic;
    color: #777;
  }
  
  @media (max-width: 768px) {
    .home-content {
      padding: 20px;
    }
  
    .home-title {
      font-size: 2em;
    }
  
    .home-subtitle {
      font-size: 1.3em;
    }
  }
  .welcome-text {
    font-size: 1.2em;
    color: #555;
    margin-bottom: 20px;
    font-style: italic;
    line-height: 1.5;
  }